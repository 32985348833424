import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { graphql, navigate } from "gatsby"
import { Flex } from "theme-ui"

import { InterfaceContext } from "../../context/interface-context"
import Button from "../base/buttons"
import Input from "../base/input-field"
import Cross from "../../assets/icons/cross.svg"

const SearchContainer = styled(Flex)`
  display: ${(props) => (props.visible ? `flex` : `none`)};
  background-color: ${(props) => props.theme.colors.background};
  flex-direction: row;
  position: fixed;
  height: 100%;
  z-index: 100;

  .search-button {
    display: default;
  }

  ${(props) => props.theme.bp.desktop} {
    height: 74px;
    width: 100%;
    margin-top: 38px;

    .search-button {
      visibility: hidden;
    }
  }
`

const SearchDrawer = () => {
  const { isSearchOpen, hideSearchDrawer } = useContext(InterfaceContext)
  const containerRef = useRef()
  const inputRef = useRef()
  //const [items, setItems] = useState()
  const [search, setSearch] = useState("")
  //const { products } = useStaticQuery(pageQuery)

  // useEffect(() => {
  //   if (products) {
  //     setItems({ all: products.nodes, visible: products.nodes.slice(0, 5) })
  //   }
  // }, [products])

  useEffect(() => {
    // add when mounted
    if (isSearchOpen) {
      if (inputRef.current) {
        inputRef.current.focus()
        inputRef.current.addEventListener("keyup", function (event) {
          // Number 13 is the "Enter" key on the keyboard
          if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault()
            // Trigger the button element with a click
            document.getElementById("search").click()
          }
        })
      }
    }
  }, [isSearchOpen])

  const handleSearch = (e) => {
    let searchValue = e.target.value
    setSearch(searchValue)
    navigate(`/search?q=${searchValue}`)
  }

  return (
    <SearchContainer visible={isSearchOpen} ref={containerRef}>
      <Input
        sx={{
          height: "100%",
          fontSize: 1,
          paddingLeft: "18px",
          borderTop: "none",

          ":focus": {
            outline: "none",
            borderTop: "none",
          },
        }}
        placeholder="Search ..."
        onChange={handleSearch}
        ref={inputRef}
        autoFocus
        outline={true}
      />
      <Button
        id="search"
        sx={{ display: "none" }}
        className="search-button"
        onClick={() => {
          hideSearchDrawer()
          navigate(`/search?q=${search}`)
        }}
      >
        Search
      </Button>
      <Flex
        sx={{
          position: "absolute",
          right: 0,
          height: "100%",
          alignItems: "center",
        }}
      >
        <Button
          variant="option"
          sx={{
            border: "none",
            marginRight: 1,
            svg: { width: "15px", fill: "darkGreen" },
          }}
          onClick={() => {
            hideSearchDrawer()
          }}
        >
          <Cross />
        </Button>
      </Flex>
    </SearchContainer>
  )
}

export default SearchDrawer

export const pageQuery = graphql`
  query {
    products: allContentfulProduct(sort: { fields: title, order: ASC }) {
      nodes {
        ...StrippedProduct
      }
    }
  }
`
