import React from "react"
import { ThemeProvider as Provider } from "theme-ui"
import { borders } from "./borders"
import { buttons } from "./buttons"
import { colors } from "./colors"
import { shadows } from "./shadows"

const theme = {
  bp: {
    desktop: "@media only screen and (min-width: 768px)",
    largeDesktop: "@media only screen and (min-width: 992px)",
    hd: "@media only screen and (min-width: 1600px)",
  },
  breakpoints: ["768px", "992px", "1600px"],
  height: [50, 70, 140, 280],
  marginTop: {
    header: 70,
    headerWithBar: 130,
  },
  width: [60, 90, 120, 240],
  fontSizes: [10, 12, 14, 16, 20, 24, 32, 48, 64],
  gap: {
    betweenProducts: [6, 14],
  },
  space: [4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "system-ui, sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  colors,
  buttons,
  shadows,
  borders,
  styles: {
    body: {
      margin: 0,
    },
  },
}

export const ThemeProvider = ({ children }) => (
  <Provider theme={theme}>{children}</Provider>
)
