import { useContext, useEffect, useState } from "react"
import { MedusaCheckoutContext } from "./checkout-provider"

export const useMedusaCheckout = () => {
  const [processingDetails, setProcessingDetails] = useState(false)
  const [processingShipping, setProcessingShipping] = useState(false)
  const [processingPayment, setProcessingPayment] = useState(false)

  const [paymentProviders, setPaymentProviders] = useState([])
  const [hasEmail, toggleEmail] = useState(false)
  const [hasBillingAddress, toggleBillingAddress] = useState(false)
  const [hasShippingAddress, toggleShippingAddress] = useState(false)
  const [hasShipping, toggleShipping] = useState(false)

  const {
    isLoading,
    cart,
    update,
    errors,
    addShippingMethod,
    setPaymentSession,
    updatePaymentSession,
    getShippingOptions,
    updateLineItem,
    removeLineItem,
    removeDiscount,
    initializePaymentSessions,
    setCartFromObject,
  } = useContext(MedusaCheckoutContext)
  const { email, items, billing_address, shipping_address, payment_sessions } =
    cart

  const handleShippingMethod = (payload) => {
    setProcessingShipping(true)
    return addShippingMethod(payload).finally(() => {
      setProcessingShipping(false)
    })
  }

  const handleUpdate = (payload) => {
    setProcessingDetails(true)
    return update(payload)
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setProcessingDetails(false)
      })
  }

  const handleUpdatePaymentSession = (session) => {
    setProcessingPayment(true)
    return updatePaymentSession(session).finally(() => {
      setProcessingPayment(false)
    })
  }

  useEffect(() => {
    toggleEmail(!!email)
  }, [email])

  useEffect(() => {
    toggleShippingAddress(
      shipping_address &&
      !!shipping_address.first_name &&
      !!shipping_address.last_name &&
      !!shipping_address.address_1 &&
      !!shipping_address.postal_code &&
      !!shipping_address.country_code &&
      !!shipping_address.phone
    )
  }, [shipping_address])

  useEffect(() => {
    toggleBillingAddress(
      billing_address &&
      !!billing_address.first_name &&
      !!billing_address.last_name &&
      !!billing_address.address_1 &&
      !!billing_address.postal_code &&
      !!billing_address.country_code &&
      !!billing_address.phone
    )
  }, [billing_address])

  // Populates convenience variables
  useEffect(() => {
    setPaymentProviders(payment_sessions?.map((s) => s.provider_id) || [])
  }, [payment_sessions])

  useEffect(() => {
    toggleShipping(items.every((i) => i.has_shipping))
  }, [items])

  return {
    isLoading,
    cart,
    errors: errors || [],

    // Convenience
    hasEmail,
    hasBillingAddress,
    hasShippingAddress,
    hasShipping,
    paymentSession: cart.payment_session,
    processingPayment,
    processingShipping,
    processingDetails,

    // Modifiers
    update: handleUpdate,
    updateLineItem,
    setCartFromObject,
    removeLineItem,
    setPaymentSession,
    initializePaymentSessions,
    updatePaymentSession: handleUpdatePaymentSession,
    paymentProviders,
    addShippingMethod: handleShippingMethod,
    removeDiscount,

    // Getters
    getShippingOptions,
  }
}
