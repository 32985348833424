import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: ${(props) => props.theme.colors.darkGreen};
  color: #fefefe;
  text-align: center;
  padding: 10px 15px;
  ${({ theme }) => theme.bp.largeDesktop} {
    padding-left: 30px;
    padding-right: 30px;
  }

  z-index: 1000;
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
  }
`

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: underline;
`

const Banner = () => {
  return (
    <BannerContainer>
      <div>
        <StyledLink to="/palmes-center-court">
          Visit our Copenhagen home!
        </StyledLink>
      </div>
    </BannerContainer>
  )
}

export default Banner
